<template>
    <div>
        <div>
            <span style="margin-right: 20px;">助梦金配置</span>
            <el-select v-model="type">
                <el-option v-for="item in zzjMode" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="applet" v-show="type == 0" style="margin-top: 20px;">
            <div>
                <span style="margin-right: 20px;">小程序id:</span>
                <el-input v-model="appId" style="width: 300px;" placeholder="请输入" clearable></el-input>
            </div>
            <div style="margin-top: 15px;">
                <span style="margin-right: 20px;">小程序路径:</span>
                <el-input v-model="path" style="width: 300px;" placeholder="请输入" clearable></el-input>
            </div>
        </div>
        <div class="h5" v-show="type == 1" style="margin-top: 20px;">
            <div>
                <span style="margin-right: 20px;">h5链接:</span>
                <el-input v-model="h5" style="width: 300px;" placeholder="请输入" clearable></el-input>
            </div>
        </div>
        <div class="noset" v-show="type == 2"></div>
        <div
            style="display: flex; width: 100%; align-items: center; flex-direction: row; justify-content: center; margin-top: 50px;">
            <el-button type="primary" size="big" @click="confirm">设置</el-button>
        </div>
    </div>
</template>
<script>
import {
    setZMJAppConfig,
    getZMJAppConfig
} from "@/api/product.js"
export default {
    mounted() {
        this.getZMJConfig()
    },
    data() {
        return {
            type: 0,
            createForm: {
                type: 0
            },
            appId: "",
            path: "",
            h5: "",
            zzjMode: [
                {
                    label: "小程序",
                    value: 0,
                },
                {
                    label: "h5",
                    value: 1,
                },
                {
                    label: "不设置",
                    value: 2,
                }
            ]
        }
    },
    methods: {
        async getZMJConfig() {
            const { code, data } = await getZMJAppConfig()
            console.log(code, data)
            if (code == 200) {
                if (data.status === 0) {
                    this.type = 2
                } else if (data.h5 != "" || data.app_id != "") {
                    if (data.h5 != "") {
                        this.type = 1
                        this.h5 = data.h5
                    } else {
                        this.type = 0
                        this.appId = data.appId,
                        this.path = data.path
                    }
                }
            } else {
                this.type = 2
            }
        },
        async confirm() {
            const params = this.getParams()
            const {code} = await setZMJAppConfig(params)
            if (code == 200) {
                this.$message({
                    type: "success",
                    message: "设置完成!",
                });
            }
        },
        getParams() {
            if (this.type == 0) {
                return {
                    app_id: this.appId,
                    path: this.path,
                    h5: "",
                    status: 1
                }
            } else if (this.type == 1) {
                return {
                    app_id: "",
                    path: "",
                    h5: this.h5,
                    status: 1
                }
            } else {
                return {
                    app_id: "",
                    path: "",
                    h5: "",
                    status: 0
                }
            }
        }
    }
}
</script>
<style></style>